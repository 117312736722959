<template>
  <AppContainer>
    <span slot="container">
      <v-card>
        <v-card-text>
          <h1 class="ma-4">Para onde deseja ir?</h1>

          <v-btn
            v-for="(btn, index) in buttons"
            :key="index"
            x-large
            color="primary"
            class="ma-2"
            @click="$router.push(btn.route)"
          >
            {{ btn.name }}
          </v-btn>
        </v-card-text>
      </v-card>
    </span>
  </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';

export default {
  name: 'HomeView',

  components: {
    AppContainer,
  },

  data: () => ({
    buttons: [
      {
        name: 'USUÁRIOS',
        route: 'users',
      },
      {
        name: 'PRODUTOS',
        route: 'produtos',
      },
      {
        name: 'PACOTES',
        route: 'pacotes',
      },
      {
        name: 'SERIAIS',
        route: 'seriais',
      },
      {
        name: 'LOJA',
        route: '/',
      },
      {
        name: 'VENDAS',
        route: 'vendas',
      },
    ]
  }),
}
</script>
